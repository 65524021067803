export const lutire_coords = {
  firstPage: {
    1: {
      point_0: [37, 704],
      point_20: [71, 704],
      point_35: [103, 704],
    },
    2: {
      point_0: [37, 641],
      point_30: [127, 641],
      point_50: [216, 641],
    },
    3: {
      point_0: [37, 578],
      point_40: [127, 578],
    },
    4: {
      point_0: [37, 515],
      point_10: [127, 515],
    },
    5: {
      point_0: [37, 452],
      point_40: [70, 452],
    },
    6: {
      point_0: [37, 388],
      point_50: [70, 388],
    },
    scores: {
      id_1: [520, 469],
    }
  }
}
