import { createRouter, createWebHistory } from 'vue-router'
import Lutire from '../views/Lutire.vue'
import i18n from '../i18n'

const LOCALES = ['en', 'ko']

const routes = [
  {
    path: '/',
    redirect: '/en'
  },
  {
    path: '/:locale',
    component: {
      template: '<router-view></router-view>'
    },
    beforeEnter: (to, from, next) => { 
      const locale = to.params.locale; 
      
      if (!LOCALES.includes(locale)) return next('en'); 

      if (i18n.locale !== locale) {
        if (i18n.mode === 'legacy') {
          i18n.global.locale = locale
        } else {
          i18n.global.locale.value = locale
        }
      }

      return next() 
    },
    children: [{
        path: '',
        name: 'Home',
        component: Lutire
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
