import axios from 'axios'
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib'
import download from 'downloadjs'
import { lutire_coords } from '@/helpers'
import useScore from '@/composables/useScore'
import messages from '@/lang'
import { useI18n } from 'vue-i18n'
import fontkit from '@pdf-lib/fontkit'

const useCreateAcssAPdf = () => {
  const { scores, calcScore } = useScore()
  const { locale } = useI18n()

  const calcWidth = (points) => {
    const MAX_WIDTH = 506.9
    const MAX_POINTS = 250

    return Math.round(points * MAX_WIDTH / MAX_POINTS)

  }

  const calcCirclePosition = (points) => {
    const widthRect = calcWidth(points)
    return widthRect + 44.2
  }

  const getPdf = () => {
    axios.get(
      `${process.env.VUE_APP_BASE_URL}/pdf/lutire_${locale.value}.pdf`,
      { responseType: 'arraybuffer'}
    ).then(res => {

        PDFDocument.load(res.data).then(async p => {

          let fontBytes = StandardFonts.Helvetica

          if (locale.value === 'ko') {
            const url = 'https://recap-cystitis.kr/fonts/NanumSquareOTF_acR.ttf'
            fontBytes = await axios.get(url, { responseType: 'arraybuffer'}).then((res) => res.data)
            p.registerFontkit(fontkit)
          }

          const helveticaFont = await p.embedFont(fontBytes)

          const pages = p.getPages()
          const firstPage = pages[0]
          
          for (let i in scores.value) {
            const quest = scores.value[i]

            if (quest && Object.keys(quest).length > 0) {
              
              for (let j in quest){
                const point = scores.value[i][j]

                if (typeof point === 'number' || typeof point === 'string') {
                  
                  const place = `point_${point}`

                  firstPage.drawText('X', {
                    x: lutire_coords.firstPage[j][place][0],
                    y: lutire_coords.firstPage[j][place][1],
                    size: 10,
                    font: helveticaFont,
                  })
                }
                
              }

            }
            
          }

          const score_1 = calcScore(scores.value['1']).toString()

          firstPage.drawRectangle({
            x: 44,
            y: 320,
            height: 3.8,
            width: calcWidth(score_1),
            color: rgb(0.211, 0.639, 0.686)
          })
          firstPage.drawRectangle({
            x: 44,
            y: 256.8,
            height: 3.8,
            width: calcWidth(score_1),
            color: rgb(0.211, 0.639, 0.686)
          })

          firstPage.drawCircle({
            x: calcCirclePosition(score_1),
            y: 336,
            size: 9,
            color: rgb(0.211, 0.639, 0.686)
          })

          firstPage.setFontColor(rgb(1,1,1))
          firstPage.drawText(score_1, {
            x: (calcCirclePosition(score_1) - 6),
            y: 334,
            size: 8,
            font: helveticaFont,
          })

          firstPage.setFontColor(rgb(0,0,0))
          if (parseInt(score_1) <= 90) {
            firstPage.drawText(messages[locale.value].lutire.low_risk, { x: 42, y: 193, size: 8, font: helveticaFont })
            firstPage.drawText(messages[locale.value].lutire.lo_point_range, { x: 42, y: 159, size: 8, font: helveticaFont })
            firstPage.drawText(messages[locale.value].lutire.lo_probability, { x: 42, y: 126, size: 8, font: helveticaFont })
            firstPage.drawText(messages[locale.value].lutire.lo_action, { x: 42, y: 95, size: 8, font: helveticaFont})
          }
          if (parseInt(score_1) <= 150 && parseInt(score_1) > 90) {
            firstPage.drawText(messages[locale.value].lutire.moderate_risk, { x: 42, y: 193, size: 8, font: helveticaFont})
            firstPage.drawText(messages[locale.value].lutire.mo_point_range, { x: 42, y: 159, size: 8, font: helveticaFont })
            firstPage.drawText(messages[locale.value].lutire.mo_probability, { x: 42, y: 126, size: 8, font: helveticaFont })
            firstPage.drawText(messages[locale.value].lutire.mo_action, { x: 42, y: 95, size: 8, font: helveticaFont })
          }
          if (parseInt(score_1) > 150) {
            firstPage.drawText(messages[locale.value].lutire.high_risk, { x: 42, y: 193, size: 8, font: helveticaFont })
            firstPage.drawText(messages[locale.value].lutire.hi_point_range, { x: 42, y: 159, size: 8, font: helveticaFont })
            firstPage.drawText(messages[locale.value].lutire.hi_probability, { x: 42, y: 126, size: 8, font: helveticaFont })
            firstPage.drawText(messages[locale.value].lutire.hi_action, { x: 42, y: 95, size: 8, font: helveticaFont })
          }

          p.save()
            .then(r => 
              download(r, 'results_lutire.pdf', 'application/pdf')
            )
        })
      })
      .catch(err => console.log(err))
  }

  return {
    getPdf
  }
}

export default useCreateAcssAPdf
