<template>
  <div>
		<img
			class="absolute top-0 right-0 z-0"
			:src="require('@/assets/bg-circle-blue.jpg')"
			style="width: 19.68vw; max-width: 378px"
		/>
    <div class="md:absolute inline-block mt-4 md:mt-0 text-center top-4 left-1/2 transform md:-translate-x-1/2 px-2.5 py-1.5 bg-secondary text-white rounded-full">
      <div v-if="locale === 'en'">
        <a href="https://recap-cystitis.com">{{ $t("lutire.back_to_recap") }}</a>
      </div>
      <div v-else-if="locale === 'ko'">
        <a href="https://recap-cystitis.kr/">{{ $t("lutire.back_to_recap") }}</a>
      </div>
    </div>
    <!-- <div class="xl:container mx-auto mb-6 mt-6"> -->
    <!--   <a href="/" :class="{ 'text-orange': locale === 'en' }" class="mr-4">EN</a> -->
    <!--   <a href="/ko" :class="{ 'text-orange': locale === 'ko' }" class="mr-4">KO</a> -->
    <!-- </div> -->
    <h1 class="text-3xl uppercase mt-5 md:text-5xl xl:container z-10 text-primary mb-5 mx-auto md:mb-5">
			Lutire
    </h1>

    <div class="w-full flex flex-col space-y-5 xl:container mx-auto relative z-10">
      <p class="text-secondary md:text-3xl w-3/4 mb-16">{{ pageData.description }}</p>

			<div class="flex flex-col space-y-5">
        <QuestionLutire
          v-for="q in pageData.questions"
          :key="q.id"
          :question="q"
          :page="pageData.id"
          @update="updateScore"
        />
      </div>

      <div class="w-full p-2.5 border border-secondary">
        <p class="pb-4 text-base md:text-xl">{{ $t( "lutire.total_points" )}}</p>

        <div class="relative h-2.5 mt-5 mx-4 md:mx-12 border border-primary">
          <span
            class="block absolute left-0 block text-sm transform -translate-x-1/2"
            style="bottom: 200%"
          >0</span>
          <span
            class="absolute left-0 block h-full w-px bg-primary bottom-0"
            style="height: 200%"
          ></span>
          <template v-for="i in 25" :key="i">
            <span
              v-if="i % 5 === 0"
              class="md:block hidden absolute block text-sm transform -translate-x-1/2"
              style="bottom: 200%"
              :style="{ left: i * 4 + '%' }"
            >{{ i * 10 }}</span>
            <span
              class="absolute hidden md:block h-full w-px bg-primary bottom-0"
              style="height: 200%"
              :style="{ left: i*4 + '%' }"
            ></span>
          </template>
          <span
            class="absolute top-0 left-0 block bg-secondary h-full"
            :style="{ width: scoreLutire * 0.4 + '%' }"
          ></span>
          <span
            class="absolute block h-full w-0.5 bg-secondary bottom-0"
            style="height: 200%"
            :style="{ left: scoreLutire * 0.4 + '%' }"
          ></span>
          <span
            class="absolute block bg-secondary text-white text-sm text-center leading-8 w-8 h-8 rounded-full transform -translate-x-1/2"
            style="bottom: 200%"
            :style="{ left: scoreLutire * 0.4 + '%' }"
          >{{ scoreLutire }}</span>
        </div>
        <span class="w-full h-px bg-secondary block my-4"></span>
        <p class="pb-4 md:text-xl">{{ $t("lutire.prob_of_12") }}</p>
        
        <div class="relative h-2.5 mt-5 mx-4 md:mx-12 border border-primary">
          <template v-for="i in 9" :key="i">
            <span
              v-if="(i !== 7) && (i !== 8) && (i !== 9)"
              class="absolute block text-sm transform -translate-x-1/2"
              style="bottom: 200%"
              :style="{ left: i * 10 + '%' }"
            >{{ '.' + (i + 1) }}</span>
            <span
              v-if="i === 8"
              class="absolute block text-sm transform -translate-x-1/2"
              style="bottom: 200%"
              :style="{ left: i * 10 + '%' }"
            >.8</span>
            <span
              v-if="i !== 7 && i !== 9"
              class="absolute block h-full w-px bg-primary bottom-0"
              style="height: 200%"
              :style="{ left: i * 10 + '%' }"
            ></span>
          </template>
          <span
            class="absolute top-0 left-0 block bg-secondary h-full"
            :style="{ width: scoreLutire * 0.4 + '%' }"
          ></span>
          <span
            class="absolute block text-sm transform -translate-x-1/2"
            style="bottom: 200%; left: 100%;"
          >.9</span>
          <span
            class="absolute block h-full w-px bg-primary bottom-0"
            style="height: 200%; left: 100%;"
          ></span>
        </div>

        <div v-if="scoreLutire <= 90">
          <div class="mt-7 pt-2.5 pl-5 pb-4 bg-secondary bg-opacity-20 border border-secondary">
            <p class="text-secondary">{{ $t("lutire.class_risk") }}</p>
          </div>
          <div class="ml-5 mt-2.5 mb-5 md:text-xl">
            <p class="text-secondary">{{ $t("lutire.class_risk") }}</p>
            <p>{{ $t("lutire.low_risk") }}</p>
            <p class="text-secondary mt-4">{{ $t("lutire.total_points")}}</p>
            <p>0-90</p>
            <p class="text-secondary mt-4">{{ $t("lutire.probability")  }}</p>
            <p>.20 - .45</p>
            <p class="text-secondary mt-4">{{ $t("lutire.actions") }}</p>
            <p>{{ $t("lutire.lo_action") }}</p>
          </div>
        </div>
        <div v-if="scoreLutire <= 150 && scoreLutire > 90">
          <div class="mt-7 pt-2.5 pl-5 pb-4 bg-secondary bg-opacity-20 border border-secondary">
            <p class="text-secondary">{{ $t("lutire.class_risk") }}</p>
          </div>
          <div class="ml-5 mt-2.5 mb-5 md:text-xl">
            <p class="text-secondary">{{ $t("lutire.class_risk") }}</p>
            <p>{{ $t("lutire.moderate_risk") }}</p>
            <p class="text-secondary mt-4">{{ $t("lutire.total_points")}}</p>
            <p>91 - 150</p>
            <p class="text-secondary mt-4">{{ $t("lutire.probability")  }}</p>
            <p>.46 - .70</p>
            <p class="text-secondary mt-4">{{ $t("lutire.actions") }}</p>
            <p>{{ $t("lutire.mo_action") }}</p>
          </div>
        </div>
        <div v-if="scoreLutire <= 250 && scoreLutire > 150">
          <div class="mt-7 pt-2.5 pl-5 pb-4 bg-secondary bg-opacity-20 border border-secondary">
            <p class="text-secondary">{{ $t("lutire.class_risk") }}</p>
          </div>
          <div class="ml-5 mt-2.5 mb-5 md:text-xl">
            <p class="text-secondary">{{ $t("lutire.class_risk") }}</p>
            <p>{{ $t("lutire.high_risk") }}</p>
            <p class="text-secondary mt-4">{{ $t("lutire.total_points")}}</p>
            <p>151 - 250</p>
            <p class="text-secondary mt-4">{{ $t("lutire.probability")  }}</p>
            <p>.71 - .99</p>
            <p class="text-secondary mt-4">{{ $t("lutire.actions") }}</p>
            <p>{{ $t("lutire.hi_action") }}</p>
          </div>
        </div>
      </div>

    </div>

    <div class="py-6 mx-auto w-full xl:container">
      <p>{{ $t("lutire.in_12_months") }}</p>
    </div>
    <div class="py-6">
      <div class="mx-auto w-full max-w-xs p-4 text-center text-bold bg-white text-secondary border border-secondary border-2 hover:text-white hover:bg-secondary transition duration-300 ease-in-out cursor-pointer"
        @click="getPdf"
      >
        <p>{{ $t("lutire.download_pdf") }}</p>
      </div>
    </div>

  </div>
</template>

<script>
import { ref, watch } from 'vue'
import useData from '@/composables/useData'
import useScore from '@/composables/useScore'
import QuestionLutire from '@/components/QuestionLutire'
import useCreateLutirePdf from '@/composables/useCreateLutirePdf'
import useLanguage from '@/composables/useLanguage'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default {
  name: 'Lutire',
  components: {
    QuestionLutire
  },
  setup() {
    const { isLoaded, pageData, getPage } = useData('lutire')
		const { init, scores, addToScore, calcScore } = useScore('lutire')
    const { getPdf } = useCreateLutirePdf()
    const router = useRouter()
    const { setLanguage } = useLanguage()
    const { locale } = useI18n()

    const activeLanguage = (lang) => {
      return locale === lang
    }

    const switchLocale = (lang) => {
      if (locale !== lang) {
        setLanguage(lang)
        const to = router.resolve({ params: {locale: lang} })
        router.push(to.fullPath)
      }
    }
    
    const scoreLutire = ref(0)

    if (isLoaded.value) {
      getPage(1)
      init()
    }

    watch(isLoaded, () => {
      getPage(1)
			if (Object.keys(scores.value).length <= 0) {
				init()
			}
    })

		const updateScore = (payload) => {
			addToScore(payload)
      scoreLutire.value = calcScore(scores.value['1'])
		}

    return {
      pageData,
      scoreLutire,
      updateScore,
      getPdf,
      locale,
      switchLocale,
      activeLanguage
    }
  }
}
</script>
