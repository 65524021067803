export default {
  en: {
    lutire: {
      back_to_recap: 'Back to recap',
      total_points: 'Total Points',
      prob_of_12: 'Probability of 12 months recurrence',
      class_risk: 'Classification Risk',
      low_risk: 'Low risk',
      probability: 'Probability',
      actions: 'Actions',
      moderate_risk: 'Moderate risk',
      high_risk: 'High risk',
      lo_action: 'Evaluation of risk factors and counselling',
      mo_action: 'Evaluation of risk factors, counselling and modify lifestyle with follow-up evaluations; active prophylaxis is recommended in motivated patients',
      hi_action: 'Evaluation of risk factors, counselling and active prophylaxis is required in all patients. Follow-up evaluation is required',
      in_12_months: '* in the last 12 months',
      download_pdf: 'Download PDF',
      lo_point_range: '0 - 99',
      mo_point_range: '91 - 150',
      hi_point_range: '151 - 250',
      lo_probability: '.20 - .45',
      mo_probability: '.46 - .70',
      hi_probability: '.71 - .99',
    }
  },
  ko: {
    lutire: {
      back_to_recap: '돌아가기 recap',
      total_points: '전체 점수',
      prob_of_12: '12개월 내 재발 가능성',
      class_risk: '위험도 분류',
      low_risk: '낮은 위험',
      probability: '재발 가능성',
      actions: '관리',
      moderate_risk: '중등도 위험',
      high_risk: '매우 위험',
      lo_action: '위험 인자를 평가하고 상담을 받으십시오.',
      mo_action: '치료의지가 있는 환자에게는 위험 요소 평가, 상담 및 추적 평가를 통한 생활 습관 수정을 통한 적극적인 예방이 권장됩니다.',
      hi_action: '모든 환자에서 위험인자 평가, 상담과 적극적인 예방 및 추적평가가 반드시 필요합니다.',
      in_12_months: '* 과거 12개월 동안',
      download_pdf: 'PDF 다운로드',
      lo_point_range: '0 - 99',
      mo_point_range: '91 - 150',
      hi_point_range: '151 - 250',
      lo_probability: '.20 - .45',
      mo_probability: '.46 - .70',
      hi_probability: '.71 - .99',
    }
  },
  de: { }
}
