<template>
  <div class="w-full border border-secondary p-2.5">
    <div>
      <p class="pb-4 text-base md:text-xl">{{ question.question }}</p>
    </div>
    <form @submit.prevent class="md:pl-12 pb-5 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-20">
      <template v-for="answer in question.answers" :key="answer.id">
        <label>
          <input
            type="radio"
            :value="answer.points"
            v-model="selectedAnswer"
            @change="updateScore"
          />
          <span class="ml-2.5 text-sm md:text-base">{{ answer.name }}</span>
        </label>
      </template>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'QuestionLutire',
  props: {
    question: Object,
    page: Number
  },
  setup(props, { emit }) {
    const selectedAnswer = ref('')
    
    const updateScore = () => {
      emit('update', {
        id: props.question.id,
        points: selectedAnswer.value,
        page: props.page
      })
    }

    return {
      selectedAnswer,
      updateScore
    }
  }
}
</script>
